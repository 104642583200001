<template>
  <div>
    <c-header :active="activeHeader" @changeActive="changeActive"></c-header>
    <div class="mock-header"></div>
    <div class="ban1">
      <div class="ban1-bg">
        <div class="wrap">
          <div class="left">
            <div class="title">由AI驱动的SCRM</div>
            <div class="dec">
              智能化引流与推广解决方案，助力您的业务全球扩展
            </div>
          </div>
          <div class="de2"></div>
          <div class="de1" ></div>
          <div class="right" v-if="false">
            <div class="de1"></div>

            <div class="de3"></div>
            <div class="de4"></div>
            <div class="de5"></div>
            <div class="de6"></div>
            <div class="de7"></div>
            <div class="de8"></div>
          </div>
          
    <!-- main Atropos container (required), add your custom class here -->
<div class="atropos my-atropos">
  <!-- scale container (required) -->
  <div class="atropos-scale">
    <!-- rotate container (required) -->
    <div class="atropos-rotate">
      <!-- inner container (required) -->
      <div class="atropos-inner">
        <!-- put your custom content here -->
        <div class="right" data-atropos-offset="-1">
         

<div class="de3" data-atropos-offset="-3"></div>
<div class="de4" data-atropos-offset="-3"></div>
<div class="de5" data-atropos-offset="2"></div>
<div class="de6" data-atropos-offset="2"></div>
<div class="de7" data-atropos-offset="-2"></div>
<div class="de8" data-atropos-offset="-2"></div>
          </div>
          
      </div>
    </div>
  </div>
</div>

        </div>
      </div>
    </div>
    <div id="product">产品功能</div>
    <div class="ban2 ban">
      <div class="wrap">
        <div class="item left">
          <div class="box_17 flex-col">
            <div class="group_9 flex-row">
              <span class="text_27">一个窗口汇集所有消息，不再有切屏烦恼</span>
              <img
                class="thumbnail_16"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <div class="image-wrapper_12 flex-col">
                <img
                  class="label_16"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG0e248b6a21ac03fab54142fdf1708e8b.png"
                />
              </div>
              <span class="text_28">多平台聚合</span>
              <div class="box_18 flex-col"></div>
              <span class="text_29"> 全渠道客户沟通平台，聚合在线聊天 </span>
            </div>
            <div class="group_10 flex-row justify-between">
              <img
                class="thumbnail_17"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_30">跨平台同步</span>
            </div>
            <span class="text_31">
              支持不同平台间的消息同步，避免信息遗漏
            </span>
            <div class="group_11 flex-row justify-between">
              <img
                class="thumbnail_18"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_32">灵活配置</span>
            </div>
            <span class="text_33">
              支持定制化的整合方式，根据业务需求个性化定制窗口
            </span>
            <div class="group_12 flex-row clickable"  @click="more">
              <div class="image-text_5 flex-row justify-between">
                <span class="text-group_5">查看更多</span>
                <img
                  class="thumbnail_19"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG40d43c1ac89a385713adb13e74ed3f24.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="item right"></div>
      </div>
    </div>
    <div class="ban3 ban">
      <div class="wrap">
        <div class="item left"></div>
        <div class="item right right-gap">
          <div class="box_17 flex-col">
            <div class="group_9 flex-row">
              <span class="text_27">支持使用强大的AI来做您的专属翻译官</span>
              <img
                class="thumbnail_16"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <div class="image-wrapper_12 flex-col">
                <img
                  class="label_16"
                  referrerpolicy="no-referrer"
                  src="./img/en2zh.png"
                />
              </div>
              <span class="text_28">实时翻译</span>
              <div class="box_18 flex-col"></div>
              <span class="text_29">
                即使您不懂客户的语言，也能与他们沟通
              </span>
            </div>
            <div class="group_10 flex-row justify-between">
              <img
                class="thumbnail_17"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_30">多语言支持</span>
            </div>
            <span class="text_31"> 支持40多种语言，涵盖市面所有场景 </span>
            <div class="group_11 flex-row justify-between">
              <img
                class="thumbnail_18"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_32">语言检测</span>
            </div>
            <span class="text_33">
              自动识别消息语言，AI翻译更具备感情色彩和当地的俚语
            </span>
            <div class="group_12 flex-row clickable"  @click="more">
              <div class="image-text_5 flex-row justify-between">
                <span class="text-group_5">查看更多</span>
                <img
                  class="thumbnail_19"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG40d43c1ac89a385713adb13e74ed3f24.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ban4 ban">
      <div class="wrap flex-row justify-between">
        <div class="item left">
          <div class="box_17 flex-col">
            <div class="group_9 flex-row">
              <span class="text_27"
                >根据业务需求定制AI员工的喜好和身份，AI客服千人千面</span
              >
              <img
                class="thumbnail_16"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <div class="image-wrapper_12 flex-col">
                <img
                  class="label_16"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG49457373f99fd2f81fa9395984600403.png"
                />
              </div>
              <span class="text_28">个性化定制</span>
              <div class="box_18 flex-col"></div>
              <span class="text_29">
                虚拟客服角色，与客户进行互动提升转化
              </span>
            </div>
            <div class="group_10 flex-row justify-between">
              <img
                class="thumbnail_17"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_30">降本增效</span>
            </div>
            <span class="text_31">
              AI数字人客服自动处理客户咨询，快速高效地提升服务水平，
              减少人工成本
            </span>
            <div class="group_11 flex-row justify-between">
              <img
                class="thumbnail_18"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_32">7*24全天候服务</span>
            </div>
            <span class="text_33"> AI员工可以提供全天候不间断的客户支持 </span>
            <div class="group_12 flex-row clickable" @click="more">
              <div class="image-text_5 flex-row justify-between">
                <span class="text-group_5">查看更多</span>
                <img
                  class="thumbnail_19"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG40d43c1ac89a385713adb13e74ed3f24.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="item right"></div>
      </div>
    </div>
    <div class="ban5 ban">
      <div class="wrap flex-row justify-between">
        <div class="item left">
          <div class="ban5-bg2"></div>
        </div>
        <div class="item right right-gap">
          <div class="box_17 flex-col">
            <div class="group_9 flex-row">
              <span class="text_27">汇总统计来自不同渠道的数据</span>
              <img
                class="thumbnail_16"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <div class="image-wrapper_12 flex-col">
                <img
                  class="label_16"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG479135d4542fb0fb3013ecca947736f7.png"
                />
              </div>
              <span class="text_28">多渠道数据整合</span>
              <div class="box_18 flex-col"></div>
              <span class="text_29">
                跟踪和分析各营销渠道的推广效果，优化推广策略
              </span>
            </div>
            <div class="group_10 flex-row justify-between">
              <img
                class="thumbnail_17"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_30">效果分析</span>
            </div>
            <span class="text_31">
              提供详细的推广效果分析报告，&nbsp;包括点击率、转化率、RO1等指标
            </span>
            <div class="group_11 flex-row justify-between">
              <img
                class="thumbnail_18"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNG2b509a601ddd319730e65e653ba333fe.png"
              />
              <span class="text_32">实时监控</span>
            </div>
            <span class="text_33">
              实时跟踪推广活动的表现，及时调整策略以提升效果
            </span>
            <div class="group_12 flex-row clickable" @click="more">
              <div class="image-text_5 flex-row justify-between">
                <span class="text-group_5">查看更多</span>
                <img
                  class="thumbnail_19"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG40d43c1ac89a385713adb13e74ed3f24.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- AI 特色 -->
    <div class="ban6 group_13 flex-col">
      <div class="text-group_6 flex-col justify-between">
        <span class="text_34">AI特色功能</span>
        <span class="text_35">
          通过云脉AI智能营销平台，让智能营销为您的业务带来无限可能！
        </span>
      </div>
      <div class="box_19 flex-row justify-between">
        <div class="group_14 flex-col justify-between">
          <div class="block_11 flex-col">
            <div class="box_20 flex-row">
              <div class="text-group_7 flex-col justify-between">
                <span class="text_36">智能对话分析</span>
                <span class="text_37">
                  AI智能分析客户对话，提取关键数据和趋势，帮助优化客服沟通策略
                </span>
              </div>
            </div>
            <div class="box_21 flex-row">
              <div
                class="image-wrapper_13 flex-col justify-center align-center"
              >
                <img
                  class="image_12"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG90d98a49a46b3967574070c27b3bb1c6.png"
                />
              </div>
              <div class="group_15 flex-col"></div>
              <div class="group_16 flex-row">
                <div class="box_22 flex-col"></div>
                <div class="box_23 flex-col"></div>
                <div class="box_24 flex-col"></div>
                <div class="box_25 flex-col"></div>
                <div class="box_26 flex-col"></div>
                <div class="box_27 flex-col"></div>
                <img
                  class="image_13"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNG56af583dc57ebe425494f66f0966ebd6.png"
                />
              </div>
            </div>
            <div class="box_28 flex-col">
              <span class="text_38">数据、趋势</span>
              <div class="image-wrapper_14 flex-col">
                <img
                  class="label_17"
                  referrerpolicy="no-referrer"
                  src="./img/FigmaDDSSlicePNGe74fd1671d6d9b58ecf60b02c09e022c.png"
                />
              </div>
            </div>
          </div>
          <div class="block_12 flex-row justify-between">
            <div class="section_19 flex-col">
              <div class="text-group_8 flex-col justify-between">
                <span class="text_39">自动化客户细分</span>
                <span class="text_40">
                  A/根据用户数据自动分类，精准创建市场细分模型，提升营销效果
                </span>
              </div>
              <div class="group_17 flex-row">
                <div class="group_18 flex-col"></div>
                <div class="group_19 flex-col"></div>
                <div class="group_20 flex-col"></div>
              </div>
              <img
                class="image_14"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNGd7da7342a5bb31c5eb8ac6358dcb5b41.png"
              />
              <img
                class="image_15"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNGcbc9c217460852da5f42c541de3c1418.png"
              />
            </div>
            <div class="section_20 flex-col">
              <div class="text-group_9 flex-col justify-between">
                <span class="text_41">情感分析与反馈</span>
                <span class="text_42">
                  AI实时分析客户反馈和评论，识别客户情感趋势和满意度，帮助改善服务体验
                </span>
              </div>
              <div class="group_21 flex-row">
                <div class="box_29 flex-col">
                  <img
                    class="image_16"
                    referrerpolicy="no-referrer"
                    src="./img/FigmaDDSSlicePNG52e91945ee6383c67fd082b328ddf51e.png"
                  />
                  <div class="box_30 flex-col"></div>
                </div>
                <div class="box_31 flex-row">
                  <img
                    class="image_17"
                    referrerpolicy="no-referrer"
                    src="./img/FigmaDDSSlicePNG4e2c6e479290f9a40bcbb464b34e1c7a.png"
                  />
                  <div class="box_32 flex-col">
                    <img
                      class="image_18"
                      referrerpolicy="no-referrer"
                      src="./img/FigmaDDSSlicePNG33fd61e8c30286b1d75348784a0ed8dd.png"
                    />
                    <div class="group_22 flex-col"></div>
                    <div class="group_23 flex-col"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="group_24 flex-col">
          <div class="text-group_10 flex-col justify-between">
            <span class="text_43">智能广告优化</span>
            <span class="text_44">
              AI自动监控广告效果，实时调整投放策略和预算，提升投资回报率
            </span>
          </div>
          <div class="section_21 flex-col">
            <div class="image-wrapper_15 flex-col">
              <img
                class="image_19"
                referrerpolicy="no-referrer"
                src="./img/FigmaDDSSlicePNGc6dde266276684e10ac1341ab4b211c5.png"
              />
            </div>
            <img
              class="image_20"
              referrerpolicy="no-referrer"
              src="./img/FigmaDDSSlicePNG636dcce5906ab7c9d1d5520e84470a94.png"
            />
          </div>
          <span class="text_45"></span>
          <div class="section_22 flex-col"></div>
        </div>
      </div>
    </div>

    <!-- 产品优势 -->
    <div class="ban7 section_26 flex-col">
      <div class="text-wrapper_4 flex-row">
        <span class="text_49">产品优势</span>
      </div>
      <div class="section_27 flex-row justify-between">
        <div class="group_25 flex-col card-hover">
          <img
            class="label_18"
            referrerpolicy="no-referrer"
            src="./img/FigmaDDSSlicePNG432ddd86b0de3d204ccddd8a31543289.png"
          />
          <span class="text_50">租用方式灵活</span>
          <div class="section_28 flex-col"></div>
          <span class="text_51">
            支持按端口和周期灵活租用，满足不同规模和需求的企业
          </span>
        </div>
        <div class="group_26 flex-col card-hover">
          <img
            class="label_19"
            referrerpolicy="no-referrer"
            src="./img/FigmaDDSSlicePNG1db398bc8c061bdc6acef1a3a31d7a69.png"
          />
          <span class="text_52">安全与隐私保护</span>
          <div class="box_37 flex-col"></div>
          <span class="text_53">
            采用AES加密方式，确保数据的安全性和隐私保护
          </span>
        </div>
        <div class="group_27 flex-col card-hover">
          <img
            class="label_20"
            referrerpolicy="no-referrer"
            src="./img/FigmaDDSSlicePNG66f56b893ea4f2a00e1ebafe8c6692d0.png"
          />
          <span class="text_54">专业技术支持</span>
          <div class="section_29 flex-col"></div>
          <span class="text_55">
            提供7*24小时技术支持，确保系统稳定运行和及时解决问题
          </span>
        </div>
      </div>
    </div>
    <div class="ban8 section_30">
      <div class="group_28 flex-col justify-end">
        <div class="text-group_12 flex-col justify-between">
          <span class="text_56">集成16+热门社交平台</span>
          <span class="text_57">打造全渠道高效营销体验</span>
        </div>
        <div class="group_29"></div>
   
      </div>
    </div>

    <c-footer></c-footer>
  </div>
</template>

<script>
import Atropos from 'atropos';

export default {
  name: "HomeView",
  components: {
  },
  data(){
    return {
      activeHeader: null,
    }
  },
  computed:{
    // activeHeader(){
    //   return  window.location.hash !== "#product" ? 0 : 1
    // }
  },
  mounted(){
    console.log('首页 mounted')
    this.activeHeader = window.location.hash !== "#product" ? 0 : 1
    const myAtropos = Atropos({
      el: '.my-atropos',
      // rest of parameters
      shadow:false
    });
  },
  methods:{
    more(){
      this.$message('敬请期待')
    },
    changeActive(url){
      this.activeHeader = url !== "product" ? 0 : 1
    }
  }
};
</script>
<style lang="scss" scoped>
// @import "@/styles/variables.scss";
@import "@/styles/lanhu_common.scss";
@import "./index.scss";

.wrap {
  width: 1400px;
  margin: 0 auto;
  position: relative;
}
.header {
  height: $header-height;
}
.mock-header {
  height: $header-height;
}

.ban1 {
  height: 582px;
  background: #e6f2ff;

  position: relative;
  .ban1-bg {
    background: url("@/assets/index/ban1-bg.png") no-repeat center center;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .left {
    position: absolute;
    width: 700px;
    height: 126px;
    top: 228px;
    left: 0px;
    gap: 0px;
    .title {
      font-family: Alibaba PuHuiTi 2;
      font-size: 48px;
      font-weight: 700;
      line-height: 67.2px;
      text-align: left;
      color: $primary-color;
    }
    .dec {
      font-family: Alibaba PuHuiTi 2;
      font-size: 28px;
      font-weight: 400;
      line-height: 39.2px;
      text-align: left;
    }
  }
  .de2 {
    position: absolute;
    width: 84px;
    height: 68px;
    top: 480px;
    right: -40px;
    gap: 0px;
    opacity: 0.4;
    // z-index: -1;
    background: url("@/assets/index/ban1-de1.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .de1 {
      position: absolute;
      width: 84px;
      height: 68px;
      top: 40px;
      left: 610px;
      gap: 0px;
      opacity: 0.4;
      background: url("@/assets/index/ban1-de1.png") no-repeat center center;
      background-size: 100% 100%;
    }
  .right {
    position: absolute;
    width: 686px;
    height: 457.33px;
    top: 64px;
    left: 714px;
    gap: 0px;
    border-radius: 20px;
    box-shadow: 0px 0px 32px 0px #0079f929;
    border: 2px solid #000000;
    background: url("@/assets/index/ban1-img1.png") no-repeat center center;


    .de3 {
      position: absolute;
      width: 449px;
      height: 100px;
      top: 317px;
      left: -30px;
      opacity: 1;
      background: url("@/assets/index/ban1-de3.png") no-repeat center center;
      background-size: 100% auto;
      border-radius: 10px;
    }
    .de4 {
      position: absolute;
      width: 64px;
      height: 64px;
      top: 220px;
      left: -13px;
      opacity: 1;
      background: url("@/assets/index/ban1-de4.png") no-repeat center center;
      background-size: 100% 100%;
      border-radius: 50%;
    }
    .de5 {
      position: absolute;
      width: 46px;
      height: 46px;
      top: -25px;
      left: 400px;
      opacity: 1;
      background-size: 100% 100%;
      background: url("@/assets/index/ban1-de5.png") no-repeat center center;
      border-radius: 50%;
    }
    .de6 {
      position: absolute;
      width: 46px;
      height: 46px;
      top: -3px;
      left: 540px;
      opacity: 1;
      background-size: 100% 100%;
      background: url("@/assets/index/ban1-de6.png") no-repeat center center;
      border-radius: 50%;
    }
    .de7 {
      position: absolute;
      width: 32px;
      height: 417px;
      top: 28px;
      right: -16px;
      opacity: 1;
      background: url("@/assets/index/ban1-de7.png") no-repeat center center;
      // background-size: 100% 100%;
    }
    .de8 {
      position: absolute;
      width: 224px;
      height: 123.68px;
      top: 280px;
      right: -40px;
      opacity: 1;
      background: url("@/assets/index/ban1-de8.png") no-repeat center top;
      background-size: 100% auto;
    }
  }
}
#product {
  padding-top: 100px;
  padding-bottom: 60px;
  font-family: Alibaba PuHuiTi 2;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
  color: #111111;
}
.ban {
  margin-bottom: 100px;
  .wrap {
    display: flex;
    justify-content: space-between;
    .item {
      width: 50%;
      height: 380px;
    }
  }
}
.ban2 {
  .right {
    background: url("@/assets/index/ban2-bg1.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
.ban3 {
  .left {
    background: url("@/assets/index/ban3-bg1.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
.ban4 {
  .right {
    background: url("@/assets/index/ban4-bg1.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
.ban5 {
  .left {
    background: url("@/assets/index/ban5-bg1.png") no-repeat center center;
    background-size: 100% 100%;
    position: relative;
    .ban5-bg2 {
      position: absolute;
      width: 277.12px;
      height: 177px;
      top: 164px;
      left: 56px;

      background: url("@/assets/index/ban5-bg2.png") no-repeat center center;
      background-size: 100% 100%;
    }
  }
}
.ban6 {
  height: 884px;
  background: url("@/assets/index/ban6-bg1.png") no-repeat center center;
  background-size: 100% 100%;
}
.ban7 {
  height: 712px;
  // background: url("@/assets/index/ban6-bg1.png") no-repeat center center;
  // background-size: 100% 100%;
}
.ban8 {
  height: 427px;
  // background: url("@/assets/index/ban8-bg1.png") no-repeat center center;
  // background-size: 100% 100%;
}

.my-atropos{
  position: absolute;
    width: 830px;
    height: 557.33px;
    top: 24px;
    left: 614px;
    gap: 0px;
    border-radius: 20px;
    // box-shadow: 0px 0px 32px 0px #0079f929;
    // background: url("@/assets/index/ban1-img1.png") no-repeat center center;
    .right{
      left:100px;
      top:40px;
    }
    .atropos-inner{
      // overflow:inherit;
    }
}
</style>