import Vue from 'vue'
import App from './App.vue'
import store from './store'

// 多页面公共 main.js
import '@/utils/commonMain'
import '@/styles/common.css'
import 'atropos/css'  //3d视差 hover

import nav from '@/utils/nav.js'
Vue.prototype.$nav = nav

Vue.config.productionTip = false


new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
